<template>
  <div coach-news>
    <div class="news-inner">
      <nav class="news-categories">
        <router-link :to="getCategoryRoute('')" v-ripple>{{ $t('_.category.ALL') }}</router-link>
        <router-link :to="getCategoryRoute('INTERVIEW')" v-ripple>{{ $t('_.category.INTERVIEW') }}</router-link>
        <router-link :to="getCategoryRoute('NEWS')" v-ripple>{{ $t('_.category.NEWS') }}</router-link>
        <router-link :to="getCategoryRoute('CONTENTS')" v-ripple>{{ $t('_.category.CONTENTS') }}</router-link>
      </nav>
      <div class="area-admin" v-if="isOrganizer">
        <basic-button tag="router-link" :to="{name: 'CoachNewsItemCreate'}">글쓰기</basic-button>
      </div>
      <div class="news-list">
        <coach-news-preview v-for="coachNewsItem in coachNewsItems" :key="coachNewsItem.newsId" :coach-news="coachNewsItem" />
        <div class="dummy" v-for="i in dummySize" :key="`dummy-${i}`" />
      </div>
      <pagination :chunkSize="size" :info="coachNewsInfo" :showSize="10" class="pagination" theme="coaching" @change="changeCursor" />
    </div>
  </div>
</template>

<script>
import cursorFetcher from '@shared/mixins/cursorFetcher';
import { getter } from 'shared/utils/storeUtils';
import CoachNewsPreview from '@/views/components/coaching/coach-news/CoachNewsPreview.vue';
import Pagination from '@/views/components/common/Pagination.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import Ripple from '@/directive/ripple';

export default {
  name: 'CoachNews',
  lexicon: 'coaching.news',
  components: { BasicButton, Pagination, CoachNewsPreview },
  directives: {
    ripple: Ripple,
  },
  mixins: [cursorFetcher({
    coachNewsInfo: async ({ services, cursor, size, category }) => services.news.getNews({ cursor, size, category }),
  })],
  data: () => ({
    /** @type{PagedCoachNews} */
    coachNewsInfo: {},
  }),
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: 'title', content: this.$t('meta.coachNews.title') },
        { vmid: 'url', content: this.$t('meta.coachNews.url') },
        { vmid: 'description', content: this.$t('meta.coachNews.description') },
        { vmid: 'keywords', content: this.$t('meta.coachNews.keywords') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
      ],
    };
  },
  computed: {
    isOrganizer: getter('auth', 'isOrganizer'),
    coachNewsItems() {
      return /** @type{CoachNews[]} */ this.coachNewsInfo?.items ?? [];
    },
    dummySize() {
      return (9 - this.coachNewsItems.length) % 3;
    },
    size: {
      get() {
        if (this.$route.query?.size) return Number(this.$route.query?.size);
        return 9; // mixinFetcher와 페이지 사이즈 일치시켜야 함
      },
      set(v) {
        const query = { ...this.$route.query, size: v, cursor: 0 };
        this.$router.push({ query });
      },
    },
    cursor: {
      get() {
        return this.$route.query?.cursor ?? 0;
      },
      set(v) {
        const query = { ...this.$route.query, cursor: v };
        this.$router.push({ query });
      },
    },
    category: {
      get() {
        return this.$route?.query?.category ?? '';
      },
      set(v) {
        const query = { ...this.$route.query, category: v };
        this.$router.push({ query });
      },
    },
  },
  methods: {
    changeCursor(cursor) {
      if (cursor === this.cursor) return;
      this.cursor = cursor;
    },
    getCategoryRoute(category) {
      const query = { ...this.$route.query };
      if (category) query.category = category;
      if (!category) delete query.category;
      return this.$router.resolve({ query }).route;
    },
    async resetData() {
      const size = this.$route.query?.size ?? 9;
      const cursor = this.$route.query?.cursor ?? 0;
      const category = this.$route.query?.category ?? '';
      await this.fetchInitAll({ cursor, size, category });
    },
  },
  watch: {
    $route: 'resetData',
  },
  mounted() {
    this.resetData();
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-news] {.bgc(#fff);
  .news-inner {.responsive-w(1280);.m(auto);.pb(64);
    > * + *:not([pagination]) {.mt(32);}
  }
  .news-list {.ml(auto);.mr(auto);.flex;.flex-jc(space-between);flex-wrap: wrap;}
  .news-list > * { .mb(32);}
  .news-list .dummy {.wh(410, 330);display: inline-block;
    @media (@ds-down) {
      .wh(286, 260);
    }
    @media (@ml-down) {
      //.wh(100%,299);
      .hide;
    }
  }
  .news-categories {.h(60);.flex;.bg-underline(#d8d8d8);
    > a { .w(130);.h(100%);.flex;.flex-ai(center);.flex-jc(center);.c(#777);.fs(16);.lh(23);font-weight: 500;}
    > a[aria-current] {.c(#191919);.bg-underline(#191919, 4px);}
  }
  .area-admin {.flex;.flex-jc(flex-end);}
  // figma상 content container의 영역 크기가 1280이므로 이에 대응하여 화면 추가
  @media (max-width: 1279px) {
    .news-iunner {.responsive-w(980);}
  }
  // breakpoint를 초과하기 시작하는 구간
  @media (@tp-down) {
    .news-list > * { .mb(20);}
    .news-inner {.pl(20);.pr(20);.pb(32);}
    .news-categories {.h(48);
      > a {.w(83);.fs(12);.lh(100%);}
    }
    .area-admin {display: block;}
  }
}
</style>
