<template>
  <router-link coach-news-preview :to="{ name: 'CoachNewsItem', params: { newsId } }">
    <!--    <cdn-img class="news-item-thumbnail" :src="thumbnailImageUrl" :alt="thumbnailImageTitle" fallback="/img/header-nav/logo-fallback.png" />-->
    <cdn-img class="news-item-thumbnail" :src="thumbnailImageUrl" :alt="thumbnailImageTitle" fallback="/img/header-nav/logo-fallback.png" dimension="820x660" />
    <h2 class="news-item-title">{{ title }}</h2>
    <p class="news-item-category">{{ categoryText }}</p>
  </router-link>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';

export default {
  name: 'CoachNewsPreview',
  lexicon: 'coaching',
  components: { CdnImg },
  props: {
    coachNews: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    cn() {
      return /** @type{CoachNews} */ this.coachNews;
    },
    title() {
      return this.cn?.title ?? '';
    },
    newsId() {
      return this.cn?.newsId ?? '';
    },
    thumbnailImageUrl() {
      return this.cn?.thumbnailImageUrl ?? '';
    },
    thumbnailImageTitle() {
      return this.cn?.thumbnailImageTitle ?? '';
    },
    categoryText() {
      const category = this.cn?.category ?? '';
      const key = `_.news.category.${category}`;
      return this.$te(key) ? this.$t(key) : category;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-news-preview] {.noto;.wh(410, 330);
  display: inline-block;
  .news-item-thumbnail {.h(230);.mb(16);.w(100%);}
  .news-item-title { .c(#191919);.fs(16);font-weight: 500;.lh(150%);.mb(8);}
  .news-item-category { .c(#a4a3ae);.fs(12);.lh(20);font-weight: 500;}
  .news-item-thumbnail .skeleton {.skeleton-bg;.h(100%);}
  @media (@ds-down) {
    .wh(286, 260);
    .news-item-thumbnail { .h(160)}
  }
  @media (@ml-down) {
    .block;
    .wh(100%, 299);
    .news-item-thumbnail { .h(199)}
  }
  .thumbnail-placeholder {.bgc(gray);content: ''}
}
</style>
